/* Display mobile menu on focus */
@media (width <= 999px) {
    #jmulibSiteHeader:focus-within {
        .top {
            height: 70vh;
            overflow: auto;
        }

        #navMobile {
            display: block;
        }
    }
}

/* Display header submenus on focus */
#jmulibSiteHeader nav[data-navMenu] {
    .menu-item:focus-within > .submenu {
        display: block;
    }
}

@media screen(lg) {
    #jmulibSiteHeader nav[data-navMenu] {
        .menu-item:focus-within > .submenu {
            display: flex;
            left: 0 !important;
            visibility: visible !important;
        }
    }
}

/* Hide header search (Primo requires javascript */
#jmulibHeaderSearch {
    display: none;
}

/* Display sr-only text after icons (which won't be injected) */
#jmulibSiteHeader,
#navBreadcrumb,
#jmulibSiteFooter {
    i {
        &.fa-brands,
        &.fa-solid {
            + .sr-only {
                line-height: normal;
                margin: unset;
                position: relative;
            }
        }
    }
}

/* Display page nav with focus */
#navPage:focus-within .menu {
    display: block;
}

/* Display Datatables (table opacity changed with datatabes.js) */
table#dataTable {
    opacity: 1 !important;
}

/* Hide Datatables search (requires javascript) */
.form:has(#dataTableSearch) {
    display: none;
}
